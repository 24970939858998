<template>
  <div class="container">
    <md-card class="dash-content">
      <PageHeader title="Transactions Management" />
      <div class="loader">
        <Wave v-if="loading || updating" width="50px" color="#d9534f" />
      </div>
      <md-table
        v-model="transactions.mdData"
        md-card
        md-sort="title"
        md-sort-order="asc"
      >
        <md-table-toolbar class="mb-4">
          <div class="md-toolbar-section-start">
            <md-button class="md-primary button-icon" @click="exportExcel">
              Download Excel
              <img src="@/assets/svg/excel.svg" />
            </md-button>
          </div>
          <div class="toolbar-filter-wrapper">
            <md-field md-clearable class="md-toolbar-section-end">
              <md-input placeholder="Search by name..." v-model="search" />
            </md-field>
          </div>
        </md-table-toolbar>

        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="S/N" md-numeric class="text-center">{{
            item.id
          }}</md-table-cell>
          <md-table-cell md-label="Merchant">{{
            item.merchant.name
          }}</md-table-cell>
          <md-table-cell md-label="Type">
            <md-button
              class="md-success md-icon md-icon-button"
              v-if="item.transaction_type == 'credit'"
            >
              <md-icon>arrow_upward</md-icon>
            </md-button>
            <md-button class="md-primary md-icon-button" v-else>
              <md-icon>arrow_downward</md-icon>
            </md-button>
          </md-table-cell>
          <md-table-cell md-label="Description">{{
            item.description
          }}</md-table-cell>
          <md-table-cell md-label="Amount">{{
            item.amount | formatMoney
          }}</md-table-cell>
          <md-table-cell md-label="Balance">{{
            item.balance | formatMoney
          }}</md-table-cell>

          <md-table-cell md-label="Date">{{
            item.created_at | formatDate
          }}</md-table-cell>
        </md-table-row>
      </md-table>
      <pagination
        v-model="transactions.mdPage"
        :records="transactions.mdCount"
        :per-page="transactions.mdPerPage"
        @paginate="updatePagination"
      />
    </md-card>
  </div>
</template>

<script>
import { PageHeader, Wave } from "@/components";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("product");

export default {
  name: "Products",
  components: {
    PageHeader,
    Wave,
  },
  data: () => ({
    search: null,
    show: false,
    updating: false,
    time: null,
    exporting: false,
  }),
  watch: {
    search(val) {
      this.waitForIt(val);
    },
  },
  methods: {
    ...mapActions(["getTransactions"]),
    searchOnTable() {
      this.searched = searchByName(this.users, this.search);
    },
    async updatePagination(page) {
      let data = { page };
      if (this.search) data.keyword = this.search;
      await this.getTransactions(data);
    },
    async submitSearch() {
      await this.getProducts({ keyword: this.search });
    },
    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
    async exportExcel() {
      this.exporting = true;
      await this.$store.dispatch("auth/export", { model: "transaction" });
      this.exporting = false;
    },
  },
  created() {
    this.searched = this.users;
  },
  mounted() {
    this.getTransactions();
  },
  computed: {
    ...mapGetters(["transactions", "loading"]),
  },
};
</script>

<style lang="scss" scoped>
// .md-field {
//   max-width: 500px;
// }
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}

.badge {
  background: #f3f3f3;

  &.success {
    background: #5cb85c;
  }

  &.danger {
    background: #d9534f;
  }
}

.modal-contain {
  max-width: 100%;
  width: 600px;
  padding: 20px;
  overflow-y: auto;

  .item {
    display: flex;
    gap: 20px;
    padding: 10px 10px;
    margin: 5px 0px;

    &:nth-child(odd) {
      background: #00ff1f0d;
    }
  }

  .media {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    .display {
      height: 150px;
      width: 150px;
      background: #ccc;
      border-radius: 5px;
      img {
        border-radius: 5px;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
.loader {
  position: absolute;
  top: 24px;
  right: 30px;
}
</style>
